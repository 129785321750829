
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTBinModal from "@/components/modals/forms/BinModal.vue";
import KTKpiModal from "@/components/modals/forms/KpiModal.vue";
import { getCustomersList, getDevicesListing } from "@/store/api/devices";
import _ from "lodash";
import e from "express";
import { fa } from "element-plus/es/locale";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repotoplatform",
  components: {
    KTDatatable,
    KTBinModal,
    KTKpiModal
  },
  setup() {
    const data = ref<any[]>([]);
    const add_devices = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const addDeviceHeader = ref([
      {
        name: "Variant Id",
        key: "variant_id",
      },
      {
        name: "Variant",
        key: "variant",
      },
      {
        name: "BB Id",
        key: "unique_random_number",
      },
      {
        name: "Pac Id",
        key: "pac_id",
      },
      {
        name: "Serial Number",
        key: "serial_number",
      },
      {
        name: "Customer",
        key: "name",
      },
      {
        name: "api doc version",
        key: "api_doc_version",
      },
      {
        name: "firmware version",
        key: "firmware_version",
      },
    ]);
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Variant Id",
        key: "variant_id",
      },
      {
        name: "Variant",
        key: "variant",
      },
      {
        name: "BB Id",
        key: "unique_random_number",
      },
      {
        name: "Pac Id",
        key: "pac_id",
      },
      {
        name: "Serial Number",
        key: "serial_number",
      },
      {
        name: "Customer",
        key: "name",
      },
      {
        name: "api_doc_version",
        key: "api_doc_version",
      },
      {
        name: "firmware_version",
        key: "firmware_version",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const checked = ref<boolean>(false);
    const customer_id = ref<string>("0");
    const triggerthebutton = ref<boolean>(false);
    const showAddDeviceTable = ref<boolean>(false);
    onMounted(() => {
      getCustomersList().then((res) => {
        customers.value = res;
      });
      loadData();
      setCurrentPageBreadcrumbs("Send Devices To Platform", [""]);
    });

    const loadData = async () => {
      loading.value = true;
      getDevicesListing(pagination.value).then((res) => {
        data.value = res.devices;
        total.value = res.total_number_of_records;
        loading.value = false;
        isDataListSelected();
      });
    };

    const isDataListSelected = () => {
      var isIncluded = add_devices.value.map(function (obj) {
        return obj.variant_id;
      });

      var isSuperset = data.value.every(function (val) {
        return isIncluded.indexOf(val.variant_id) >= 0;
      });
      checked.value = isSuperset;
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    const isChecked = (val) =>
      add_devices.value.find((element) => {
        if (element.variant_id === val) {
          return true;
        } else false;
      });

    const unCheck = (val) => {
      add_devices.value.splice(
        add_devices.value.findIndex((a) => a.variant_id === val),
        1
      );
      showAddDeviceTable.value = false;
    };

    const selectAll = (val) => {
      if (val === true) {
        data.value.forEach((x) => {
          const { variant, variant_id, unique_random_number } = x;
          const exists = add_devices.value.some(
            (d) => d.variant_id === variant_id
          );
          if (!exists) {
            add_devices.value.push({ variant, variant_id, unique_random_number });
          }      
        });
        if (add_devices.value.length > 0) {
          showAddDeviceTable.value = true;
        } else {
          showAddDeviceTable.value = false;
        }
      } else {
        add_devices.value = add_devices.value.filter(
          (d) => !data.value.find((rm) => rm.variant_id === d.variant_id)
        );
        if (add_devices.value.length > 0) {
          showAddDeviceTable.value = true;
        } else {
          showAddDeviceTable.value = false;
        }
      }
    };

    // const removeDuplicatedObj = () => {
    //   add_devices.value = _.uniqBy(add_devices.value, function (e) {
    //     return e.variant_id;
    //   });
    // };
    const selectCategory = (event) => {
      customer_id.value = event.target.value;
      triggerthebutton.value = true;
      //  document.getElementById('hidebuttonid').style.display = 'block';
    };

    const selectDevice = (val) => {
      const { variant, variant_id, unique_random_number } = val;
      if (!isChecked(variant_id)) {
        add_devices.value.push({ variant, variant_id, unique_random_number });
        showAddDeviceTable.value = true;
      } else {
        unCheck(val.variant_id);
      }
    };

    return {
      data,
      add_devices,
      headerConfig,
      customers,
      total,
      loading,
      search,
      pagination,
      cardData,
      currentChange,
      searchItems,
      getCustomerName,
      itemsPerPageChange,
      onCustomerChange,
      selectDevice,
      isChecked,
      unCheck,
      selectAll,
      checked,
      addDeviceHeader,
      customer_id,
      selectCategory,
      loadData,
      triggerthebutton,
      showAddDeviceTable,
    };
  },
});
